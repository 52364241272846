import React from 'react';
import { CSSObject, FlexProps, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  buttonBonusBetsProps: {
    display: 'flex',
    variant: 'solid',
    h: '26px',
    px: '1.5',
    transition: 'all .3s ease-in-out',
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px rgba(254, 210, 93, 1) inset, 0px 0px 0px 3px rgba(0, 43, 106, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.3) inset',
    spanProps: {
      fontFamily: 'accent',
      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
      fontWeight: 'normal',
      fontSize: '11px',
      pt: '0.5',
      color: 'beta.500',
    },
    _disabled: {
      boxShadow: 'none',
      border: 'none',
      bg: 'blackAlpha.300',
      color: 'beta.50',
      opacity: '1',
      '& > span': {
        _first: {
          color: 'green.300',
          mr: '1',
        },
      },
    },
    sx: {
      '.chakra-button__icon': {
        display: 'none',
      },
      ':active, &[data-active="true"]': {
        color: 'beta.100',
        bg: 'beta.600',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        textShadow: 'unset',
      },
      '&&[data-isbonusbet="true"]': {
        _disabled: {
          w: '100%',
          mt: '-21px',
          bg: 'blackAlpha.300',
          borderRadius: 'none',
          borderBottomRadius: 'md',
          boxShadow: 'none',
          '.chakra-button__icon': {
            display: 'block',
            color: 'green.300',
            mr: '0.5',
          },
          span: {
            fontWeight: 'medium',
            fontSize: '2xs',
            fontFamily: 'body',
            color: 'white',
            pt: '0.5',
            textTransform: 'uppercase',
          },
        },
        color: 'beta.500',
        bg: 'alpha.900',
        border: 'none',
        px: '1.5',
        h: '26px',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.25)',
        spanProps: {
          fontFamily: 'accent',
          fontWeight: 'normal',
          fontSize: '11px',
          pt: '0.5',
        },
      },
    },
  },
  flexWrapperMysteryBetFooterBetReview: {
    borderRadius: 'md',
    bg: 'alpha.800',
    boxShadow:
      '0px 2px 0px 0px  var(--bc-colors-blackAlpha-500) inset, 0px 4px 4px 0px var(--bc-colors-blackAlpha-400)',
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'heading',
    w: '-webkit-fill-available',
    color: 'beta.500',
    h: 'auto',
  },
  spanBetOdds: {
    fontSize: 'sm',
    fontWeight: 'normal',
  },
  spanRaceInfo: {
    fontWeight: 'normal',
  },
  flexRaceInfoOverride: {
    fontSize: 'sm',
  },
  buttonBetPropositionClosedProps: {
    variant: 'unstyled',
    bg: 'orange.500',
    boxShadow:
      '0px 2px 4px 0px var(--bc-colors-blackAlpha-400), 0px 1px 0px 0px var(--bc-colors-whiteAlpha-400) inset',
    borderRadius: 'base',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'xs',
    px: '3',
    py: '2',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  buttonStakeProps: {
    variant: 'secondary',
  },
  betCardSVG: {
    color: 'rgba(255, 198, 42, 1)',
    fill: '#6f4b01',
  },
};

export const betCardErrorFlexTextProps: FlexProps = {
  color: 'red.100',
  textShadow: '0px 1px 1px var(--bc-colors-blackAlpha-500)',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const betCardErrorFlexProps: FlexProps = {
  boxShadow: '0px 3px 8px 0px var(--bc-colors-blackAlpha-500)',
  color: 'red.100',
  bg: 'red.700',
  borderRadius: 'md',
  w: '95%',
  mx: 'auto',
  mt: '1',
  justifyContent: 'center',
  minH: '8',
  alignItems: 'center',
  px: '2',
  py: '1',
};

export const BetCardContainer = (): CSSObject => ({
  boxShadow:
    '0px 0px 0px 1px var(--bc-colors-alpha-300)inset, 0px 4px 10px 0px var(--bc-colors-blackAlpha-500)',
  color: 'alpha.50',
  mb: '-2.5',
  bg: 'alpha.400',
  borderRadius: 'md',
  '.state-prior': { color: 'white' },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'alpha.50',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'semibold',
  fontSize: 'sm',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'white',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'beta.500',
  fontWeight: 'bold',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'medium',
  '&&': {
    color: 'white',
  },
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  ml: '8',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bgGradient: 'linear(to-b, white, white)',
  boxShadow:
    '0px 4px 6px var(--bc-colors-blackAlpha-400), inset 0px 1px 0px white',
  color: 'gray.600',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'beta.500' : 'white',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
  filter: 'drop-shadow(0px 2px 4px var(--bc-colors-blackAlpha-400))',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
  flexWrap: 'wrap',
  py: '2',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);

export const EventRule: CSSObject = {
  color: 'white',
};

export const BetOdds: CSSObject = {
  mb: '2px',
};

export const DefaultStakeFlexbox: CSSObject = {
  gap: '1',
};

export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
  pr: '2',
  pl: '10',
};
