import { CSSObject } from '@chakra-ui/react';

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['5', null, '8'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  bg: 'white',
  borderTopRightRadius: ['none', null, 'lg'],
  borderBottomRightRadius: ['none', null, 'lg'],
  '> div:first-child': {
    color: 'beta.800',
    fontSize: 'lg',
    fontFamily: 'heading',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  form: {
    label: {
      color: 'beta.800',
      fontSize: 'xs',
      fontWeight: 'semibold',
      fontFamily: 'body',
    },
    '.chakra-form__error-message': {
      bg: 'red.600',
      boxShadow:
        '0px 2px 4px 0px var(--bc-colors-accent-400), 0px 1px 0px 0px var(--bc-colors-whiteAlpha-400) inset',
      borderRadius: 'md',
      color: 'white',
      fontWeight: 'medium',
      fontSize: 'xs',
      fontFamily: 'body',
    },
    'select[name*="title-dropdown"], #state-select, #suburb-input, #postcode-input, #residential-input, #streetName-input, #streetNumber-input':
      {
        h: '38px',
        borderRadius: 'md',
        border: '1px',
        borderColor: 'gray.300',
        background: 'gray.50',
        color: 'gray.600',
        fontWeight: 'medium',
        fontFamily: 'body',
        boxShadow: [
          '0px 1px 5px 0px var(--bc-colors-blackAlpha-300) inset,0px 1px 0px 0px var(--bc-colors-whiteAlpha-50)',
          null,
          'none',
        ],
      },
    '[class*=chakra-checkbox]': {
      borderColor: 'gray.400',
      alignItems: 'center',
      '&[data-checked]': {
        bg: 'unset',
        borderColor: 'beta.800',
        color: 'beta.800',
        borderRadius: 'sm',
        svg: {
          color: 'beta.800',
          fontSize: '2xs',
        },
      },
      '& .chakra-checkbox__control': {
        borderRadius: 'sm',
        height: '4',
        width: '4',
        borderWidth: '1px',
      },
    },
    'button[aria-label*="password toggle"]': {
      top: '0.5',
      color: 'gray.600',
    },
    'ul[class*="AutoCompleteResultContainer"]': {
      fontFamily: 'body',
    },
  },
};
